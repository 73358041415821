import { Controller } from '@hotwired/stimulus'
import Uploader from '../helpers/uploader'

export default class extends Controller {
  static targets = ['form', 'dropzone', 'input', 'filename', 'progress', 'check', 'submit']

  declare readonly formTarget: HTMLFormElement
  declare readonly dropzoneTarget: HTMLDivElement
  declare readonly inputTarget: HTMLInputElement
  declare readonly filenameTarget: HTMLParagraphElement
  declare readonly progressTarget: HTMLProgressElement
  declare readonly checkTarget: HTMLDivElement
  declare readonly submitTarget: HTMLInputElement

  acceptFile (event: any): void {
    event.preventDefault()
  }

  dropFile (event: any): void {
    event.preventDefault()
    const file: File = event.dataTransfer?.files[0]
    if (!file) return
    this.uploadFile(file)
  }

  openFileDialog (event: any): void {
    event.preventDefault()
    this.inputTarget.click()
  }

  onInputChange (event: any): void {
    event.preventDefault
    if (this.inputTarget.files != null) {
      this.uploadFile(this.inputTarget.files[0])
    }
    this.inputTarget.value = ''
  }

  uploadFile (file: File): void {
    // your form needs the file_field: 'direct_upload: true', which provides data-direct-upload-url
    const url = this.inputTarget.dataset.directUploadUrl
    if (!url) {
      console.error("Url couldn't be parsed from input field")
      return
    }

    this.filenameTarget.hidden = true
    this.checkTarget.hidden = true
    this.submitTarget.disabled = true

    const uploader = new Uploader(file, url, this)
    this.progressTarget.value = 0
    this.progressTarget.hidden = false
    uploader.uploadFile(file)
  }

  onFileInputChange (): void {
    const file = (this.inputTarget.files != null) ? this.inputTarget.files[0] : null
    if (file == null) return
    this.uploadFile(file)
  }
}
