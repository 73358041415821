import { DirectUpload } from '@rails/activestorage'

export default class Uploader {
  private readonly upload: DirectUpload
  private readonly controller: any

  constructor (file: File, url: string, controller: any) {
    this.upload = new DirectUpload(file, url, this)
    this.controller = controller
  }

  uploadFile (file: File): void {
    this.upload.create((error, blob) => {
      if (error) {
        console.error('Upload failed', error)
      } else {
        const hiddenField: HTMLInputElement = document.createElement('input')
        hiddenField.setAttribute('type', 'hidden')
        hiddenField.setAttribute('value', blob.signed_id)
        hiddenField.name = this.controller.inputTarget.name

        this.controller.formTarget.querySelectorAll('form>input[name=\'download[file]\']').forEach((fileElement: HTMLInputElement) => {
          fileElement.remove()
        })
        this.controller.formTarget.appendChild(hiddenField)
        this.controller.filenameTarget.textContent = file.name
      }
    })
  }

  directUploadWillStoreFileWithXHR (request: XMLHttpRequest) {
    request.upload.addEventListener('progress', event => {
      this.directUploadDidProgress(event)
      this.directUploadDidComplete(event)
    })
  }

  directUploadDidProgress (event: ProgressEvent): void {
    if (event.lengthComputable) {
      this.controller.progressTarget.value = Math.round((event.loaded / event.total) * 100)
    }
  }

  directUploadDidComplete (event: ProgressEvent): void {
    if (event.loaded == event.total) {
      this.controller.filenameTarget.hidden = false
      this.controller.submitTarget.disabled = false
      this.controller.checkTarget.hidden = false
    }
  }
}
