import { Controller } from '@hotwired/stimulus'
import { type HTMLInputEvent } from '../types/html_event'

// Connects to data-controller="event-map-builder"
export default class extends Controller {
  static targets = ['mapWrapper', 'mapContainer', 'mapImage', 'point', 'noMap']
  static values = { eventUrl: String }

  eventUrlValue!: string

  readonly mapWrapperTarget!: HTMLDivElement
  readonly mapContainerTarget!: HTMLElement
  readonly mapImageTarget!: HTMLImageElement
  readonly pointTargets!: HTMLElement[]
  readonly noMapTarget!: HTMLElement

  readonly hasNoMapTarget!: boolean

  declare updateAllocationUrlValue: string
  declare updateGroupUrlValue: string
  declare updateImageUrlValue: string
  declare removeImageUrlValue: string

  // Points have height and width of 22px.
  // To render the point correctly centered, the point must be shifted up and left by half its height/width.
  POINT_RADIUS = 11

  connect (): void {
    this.updateAllocationUrlValue = this.eventUrlValue + '/update_ex_allocation'
    this.updateGroupUrlValue = this.eventUrlValue + '/update_ex_group'
    this.updateImageUrlValue = this.eventUrlValue + '/update_map'
    this.removeImageUrlValue = this.eventUrlValue + '/remove_map'

    this.setPointPositions()
  }

  setPointPositions (): void {
    this.pointTargets.forEach((point) => {
      const x = (Number(point.getAttribute('data-x')) ?? 0) - this.POINT_RADIUS
      const y = (Number(point.getAttribute('data-y')) ?? 0) - this.POINT_RADIUS

      if (x > 0 && y > 0) {
        point.style.position = 'absolute'
        point.style.left = `${x}px`
        point.style.top = `${y}px`
      }
    })
  }

  async loadImage (event: HTMLInputEvent): Promise<void> {
    const files = event.target.files
    if (files === null) return

    const file = files[0]

    try {
      const response = await this.updateImage(this.updateImageUrlValue, file)
      if (!response.ok) throw new Error('Invalid response code')

      const reader = new FileReader()
      reader.onload = this.setImageSource.bind(this)
      reader.readAsDataURL(file)
      if (this.hasNoMapTarget) { this.noMapTarget.style.display = 'none' }
    } catch {
      window.alert('Could not upload map image. Please try again later.')
    }
  }

  async removeImage (): Promise<void> {
    if (confirm('Are you sure?')) {
      try {
        const response = await fetch(this.removeImageUrlValue, {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': this.getToken()
          }
        })
        if (!response.ok) throw new Error('Invalid response code')
        if (this.hasNoMapTarget) { this.noMapTarget.style.display = 'block' }
      } catch {
        window.alert('There is no map for this event.')
      }
    }
  }

  setImageSource (event: ProgressEvent<FileReader>): void {
    if (event.target === null) return

    const result = event.target.result?.toString()
    if (result === undefined) return

    this.mapImageTarget.setAttribute('src', result)
  }

  drag (event: DragEvent): void {
    event.preventDefault()
    const point = event.currentTarget as HTMLElement
    if (point === null) return

    const minX = 0
    const maxX = this.mapImageTarget.width
    const minY = 0
    const maxY = 424

    const offsetX = event.clientX - point.getBoundingClientRect().left
    const offsetY = event.clientY - point.getBoundingClientRect().top

    const previousX = ((event.clientX - offsetX + window.scrollX) - this.mapContainerTarget.offsetLeft) + this.mapWrapperTarget.scrollLeft
    const previousY = ((event.clientY - offsetY + window.scrollY) - this.mapContainerTarget.offsetTop)

    point.style.transitionDuration = ''
    point.style.position = 'absolute'

    const movePoint = (event: MouseEvent): void => {
      const xPosition = ((event.clientX - offsetX + window.scrollX) - this.mapContainerTarget.offsetLeft) + this.mapWrapperTarget.scrollLeft
      const yPosition = ((event.clientY - offsetY + window.scrollY) - this.mapContainerTarget.offsetTop)

      point.style.left = `${xPosition}px`
      point.style.top = `${yPosition}px`
    }

    const dropPoint = async (): Promise<void> => {
      document.removeEventListener('mousemove', movePoint)
      document.removeEventListener('mouseup', dropPointWrapper)

      const xPosition = point.offsetLeft + this.POINT_RADIUS
      const yPosition = point.offsetTop + this.POINT_RADIUS

      if (xPosition < minX || xPosition > maxX || yPosition < minY || yPosition > maxY || this.mapImageTarget.src === '') {
        resetPoint()
      } else {
        try {
          const { targetType } = point.dataset
          const url = targetType === 'ExAllocation' ? this.updateAllocationUrlValue : this.updateGroupUrlValue
          const response = await this.updateEXTarget(url, Number(point.id), xPosition, yPosition)
          if (!response.ok) {
            resetPoint()
          }
        } catch {
          resetPoint()
        }
      }
    }

    const resetPoint = (): void => {
      point.style.transitionDuration = '.3s'
      point.style.left = `${previousX}px`
      point.style.top = `${previousY}px`
    }

    const dropPointWrapper = (): void => {
      dropPoint()
        .catch((error) => {
          console.error('Error in dropPoint:', error)
        })
    }

    document.addEventListener('mousemove', movePoint)
    document.addEventListener('mouseup', dropPointWrapper)
  }

  async updateEXTarget (url: string, targetId: number, x: number, y: number): Promise<Response> {
    return await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': this.getToken()
      },
      body: JSON.stringify({ target_id: targetId, x, y })
    })
  }

  async updateImage (url: string, file: File): Promise<Response> {
    const formData = new FormData()
    formData.append('file', file)
    return await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': this.getToken()
      },
      body: formData
    })
  }

  getToken (): string {
    const tokenElm = document.querySelector('meta[name="csrf-token"]')
    let token = ''
    if (tokenElm !== null) {
      token = tokenElm.getAttribute('content') ?? ''
    }
    return token
  }
}
